import Vue from 'vue';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import Navigation from '../application/javascripts/navigation';
import CookiesNotice from '../application/javascripts/CookiesNotice.vue';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
Turbolinks.start();

Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('cookies-notice', CookiesNotice);
Vue.component('livecasts-stream', () => import('../application/javascripts/LivecastsStream.vue'));

let vueApp;

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  vueApp = new Vue({ el: '#js-wrapper' });
});

document.addEventListener('turbolinks:visit', () => {
  if (vueApp) vueApp.$destroy();
});
