<template>
  <div v-if="visible" class="fixed bottom-0 left-0 right-0 bg-primary py-4 z-20">
    <div class="container">
      <div class="flex flex-col sm:flex-row items-center">
        <p class="text-white text-sm sm:mr-4">
          <slot name="text" />
        </p>
        <button
          type="button"
          class="btn -small btn-white text-base px-4 md:ml-auto mt-4 sm:mt-0"
          @click="dismiss"
        >
          <slot name="button" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
const COOKIE_NAME = 'cookies-notice-dismissed';
const COOKIE_LIFETIME = 356; // 1 year

export default {
  data() {
    return { visible: true };
  },

  mounted() {
    this.visible = !Cookies.get(COOKIE_NAME);
  },

  methods: {
    dismiss() {
      this.visible = false;

      Cookies.set(COOKIE_NAME, 'dismissed', { expires: COOKIE_LIFETIME });
    }
  }
};
</script>
